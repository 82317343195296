var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('k-text',{staticClass:"title mt-n1",attrs:{"text":_vm.data.investmentValues[_vm.index].subject}}),_c('k-array-input',{attrs:{"defaultValue":{
      subject: '',
      value: _vm.data.investmentValues[_vm.index].value,
      qty: null,
      unit: '',
      pricePerUnit: null,
      acquisitionValue: null
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('k-text-input',{attrs:{"label":"Uraian"},model:{value:(item.subject),callback:function ($$v) {_vm.$set(item, "subject", $$v)},expression:"item.subject"}}),_c('k-text-input',{attrs:{"label":"Banyaknya","type":"number"},on:{"input":function($event){return _vm.countAcquisitionValue(item)}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", _vm._n($$v))},expression:"item.qty"}}),_c('k-combobox-input',{attrs:{"label":"Satuan","placeholder":"Pilih satuan","item-text":"name","item-value":"name","items":_vm.unitStore.units},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}}),_c('k-currency-input',{attrs:{"label":"Harga Satuan","prefix":"Rp. "},on:{"input":function($event){return _vm.countAcquisitionValue(item)}},model:{value:(item.pricePerUnit),callback:function ($$v) {_vm.$set(item, "pricePerUnit", $$v)},expression:"item.pricePerUnit"}}),_c('k-currency-input',{key:index + '-' + _vm.reRenderCount,attrs:{"readonly":"","label":"Nilai Perolehan","prefix":"Rp. "},model:{value:(item.acquisitionValue),callback:function ($$v) {_vm.$set(item, "acquisitionValue", $$v)},expression:"item.acquisitionValue"}})]}}]),model:{value:(_vm.data.investmentValues[_vm.index].data),callback:function ($$v) {_vm.$set(_vm.data.investmentValues[_vm.index], "data", $$v)},expression:"data.investmentValues[index].data"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }